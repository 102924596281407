import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Scroll from "../components/Scroll"

const contact = () => {
  return (
    <Layout>
      <Scroll showBelow={250}/>
      <SEO title="Contáctanos" description="Envíanos un correo" />
      <section className="contact-page">
      
        <article className="contact-form">
          <h3> ¡Contáctanos!</h3>
          <p>
            No lo dejes solo en una idea, comienza tu proyecto con nosotros:
          </p>
          <form action="https://formspree.io/f/xnqokevq" method="POST">
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="nombre"
                className="form-control"
              />
              <input
                type="text"
                name="tel"
                placeholder="Teléfono"
                className="form-control"
              />
              <input
                type="email"
                name="email"
                placeholder="correo"
                className="form-control"
              />
              <textarea
                name="message"
                placeholder="mensaje"
                className="form-control"
                rows="5"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn btn">
              enviar
            </button>
          </form>
        </article>
      </section>
    </Layout>
  )
}

export default contact
